.siteContainer {
  display: flex;
  /* margin: 50px 10% 0px; */
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
}

.container {
    display: flex;
    max-height: 100%;
    margin-bottom: 50px;
    justify-content: center;
}

.container:last-child{
  margin-bottom: 0;
}

.leftSide {
  flex-direction: row;
}

.rightSide {
  flex-direction: row-reverse;
}

.image {
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 400px;
  box-shadow: 1px 1px 5px #000000;
}

.title {
  margin: auto;
}
  
.contentDescription {
  margin-top: 5px;
  max-width: 500px;
}

.contenTitle {
  
}

.seeMoreBtn {
  color:  rgb(31,201,150);
  border: 1px solid rgb(31,201,150);
  padding: 7px 20px;
  background-color: white;
  max-width: 150px;
}

.seeMoreBtn:hover {
  background-color: rgb(31,201,150);
  color: white;
}

.contentDiv {
  display: flex;
  flex-direction: column;
  margin: 20px auto 0;
  padding: 0 40px;
}
  
@media only screen and (max-width: 800px) {
  .container {
    flex-direction: column;
  }

  .contentDiv {
    margin-left: 0;
    padding-left: 0;
  }

  .siteContainer {
    margin: 20px 0px;
  }

  .contentDiv {
    margin-top: 20px;
    margin-left: 10%;
  }
}
    